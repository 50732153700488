/* cyrillic-ext */
@font-face {
  font-family: '__Comfortaa_b1e078';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/f333cc3479ed4d1d-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Comfortaa_b1e078';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/d058ce612f85345d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Comfortaa_b1e078';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/b90ec1d97f848a2c-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Comfortaa_b1e078';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/c3008883a8bdda3d-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Comfortaa_b1e078';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/8da2e0e72f0432cc-s.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Comfortaa_b1e078';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/7a7c1dbbf88cd759-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Comfortaa_Fallback_b1e078';src: local("Arial");ascent-override: 74.60%;descent-override: 19.81%;line-gap-override: 0.00%;size-adjust: 118.10%
}.__className_b1e078 {font-family: '__Comfortaa_b1e078', '__Comfortaa_Fallback_b1e078';font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Code_32a5ef';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/02b0fb833c223124-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Code_32a5ef';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/2e1f49a845c12876-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Code_32a5ef';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/49b4b4779109cbcf-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Code_32a5ef';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/3b49a01c1c3dbb5c-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Code_32a5ef';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/42363274585c0409-s.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Code_32a5ef';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/9c6817ab9c992ff9-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Fira_Code_Fallback_32a5ef';src: local("Arial");ascent-override: 75.29%;descent-override: 24.49%;line-gap-override: 0.00%;size-adjust: 131.49%
}.__className_32a5ef {font-family: '__Fira_Code_32a5ef', '__Fira_Code_Fallback_32a5ef';font-style: normal
}

